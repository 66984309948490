import { useEffect } from "react";
import { API } from "../service/api";
// import logo from "../assets/Logo.svg";
import { useAuth } from "../context/AuthContext";
import { login, logout } from "../service/auth";

export const Spinner = () => {
  const { setAuthenticated, setUser } = useAuth();

  useEffect(() => {
    load();
  }, []);

  async function load() {
    try {
      const response = await API.get("/auth/profile", {});

      setUser(response.data.data.user);
      setAuthenticated(true);
      login(response.data.data.access_token);
    } catch (error) {
      console.log(error);
      logout();
      setAuthenticated(false);
    }
  }

  return (
    <div
      id="spinner"
      className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
    >
      <div
        className="spinner-border text-primary"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
      />
    </div>
  );
};
